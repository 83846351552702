
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ChucVuService from "@/core/services/ChucVu.service";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { PositionBaseResponse } from "@/core/interfaces/ApiResponses";

export default defineComponent({
  name: "position-form",
  setup() {
    const route = useRoute();
    const router = useRouter();

    // form elements
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);

    // local state
    const isCreating = ref(true);
    const positionDetail = ref<PositionBaseResponse | null>(null);

    // form state
    const form = reactive({
      name: "",
      shortName: "",
    });
    const rules = reactive({
      name: [
        {
          required: true,
          message: "Phải nhập tên của chức vụ",
          trigger: "blur",
        },
      ],
      shortName: [
        {
          required: true,
          message: "Phải nhập tên viết tắt của chức vụ",
          trigger: "blur",
        },
      ],
    });

    onMounted(async () => {
      isCreating.value = route.name === "new-position-form";
      setCurrentPageTitle("Trang quản lý chức vụ");

      try {
        if (!isCreating.value) {
          const {
            data: { data },
          } = await ChucVuService.get(+route.params.id);
          if (data) {
            positionDetail.value = { ...data };
            form.name = positionDetail.value.ten_chuc_vu;
            form.shortName = positionDetail.value.ten_viet_tat;
          }
        }
      } catch {
        Swal.fire({
          text: "Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin chức vụ, mời bạn quay lại sau",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    });

    const resetForm = () => {
      form.name = "";
      form.shortName = "";
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          let fetchedId = route.params.id || -1;

          try {
            if (isCreating.value) {
              const {
                data: {
                  data: { id },
                },
              } = await ChucVuService.create(form.name, form.shortName);
              fetchedId = id;
            } else {
              await ChucVuService.update(
                +fetchedId,
                form.name,
                form.shortName
              );
            }
            await Swal.fire({
              text: `Chức vụ được ${
                isCreating.value ? "tạo mới" : "chỉnh sửa"
              } thành công`,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Đã hiểu",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            router.push(`/quan-ly/chuc-vu/chi-tiet/${fetchedId}`);
          } catch {
            Swal.fire({
              text: `Xin lỗi, không thể ${
                isCreating.value ? "tạo mới" : "chỉnh sửa"
              } chức vụ không thành công, mời bạn thực hiện lại`,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Đã hiểu",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        } else {
          return false;
        }
      });
    };

    return {
      loading,
      formRef,
      form,
      rules,
      isCreating,
      positionDetail,
      onSubmit,
      resetForm,
    };
  },
});
